import { getAjaxError } from "./misc";
import { showResellerNote } from "./reseller-note";

const $html = $("html");
const summaryLink = $html.data("checkout-summary-url");
const $checkoutDropdown = $(".checkout-preview-dropdown");
const $badge = $(".navbar__brand__checkout .badge");

function track(section, action, category) {
  const config = JSON.parse(localStorage.getItem("cookie_config"));
  if (config && config.a && window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: section,
    });
  }
}

// keep this export for react variant picker
export const onAddToCheckoutError = (response) => {
  const errorText = getAjaxError(response);
  const $error = $(".product__info__form-error");
  $error.html(errorText);
  $error.attr("hidden", false);
  track(errorText, "Error", "Shop");
  const e = new Error();
  plausibleEvent("Error",{ 
    props: { 
      source_url: location.href,
      message: errorText,
      stack: e.stack
    } 
  });
};

export const onAddToCheckoutSuccess = () => {
  $.get(summaryLink, (data) => {
    $checkoutDropdown.html(data);
    const newQunatity = $(".checkout-preview-dropdown__total").data("quantity");
    $badge.html(newQunatity).removeClass("empty");
  });
};

function updateFragments() {
  const $isHomeOrCart = $(".home__products-overview, .cart .checkout-preview");
  if ($isHomeOrCart.length) {
    $.ajax({
      url: location.href,
      type: "GET",
      success: (body) => {
        const fragment = document.createDocumentFragment();
        $(fragment).html(body);

        // Update incoterm-note
        const existingIncotermNote = document.body.querySelector("#incoterm-note");
        let newIncotermNote = fragment.querySelector("#incoterm-note");
        existingIncotermNote.replaceWith(newIncotermNote);

        // Hide all existing banners
        const existingBanners = document.querySelectorAll("[data-shop-banner]") || [];
        existingBanners.forEach((existingBanner) => {
          existingBanner.hidden = true;
        });

        // Show new banners
        const newBanners = fragment.querySelectorAll("[data-shop-banner].banner") || [];
        const navbar = document.querySelector(".navbar")
        newBanners.forEach((newBanner) => {
          const bannerId = newBanner.getAttribute('data-shop-banner');
          const button = newBanner.querySelector(`button[data-shop-banner="${bannerId}"]`);
          if (localStorage.getItem(`shop_banner_${bannerId}`) === 'hidden') {
              newBanner.hidden = true;
              return;
          } else {
            navbar.after(newBanner);
          }
          if (button) {
            button.addEventListener('click', () => {
              newBanner.hidden = true;
              localStorage.setItem(`shop_banner_${bannerId}`, 'hidden');
            });
          }
        });

        // Update prices
        fragment
          .querySelectorAll(".home__products-overview [data-product-variant]")
          .forEach((product) => {
            const variant = product.dataset.productVariant;
            $(`[data-product-variant="${variant}"]`).replaceWith(product);
          });
        fragment.querySelectorAll(".checkout-preview [data-product-id]")
          .forEach((item) => {
            const variant = item.dataset.productId;
            $(`[data-product-id=${variant}]`).replaceWith(item);
          });
      },
    });
  }
}

export default $(document).ready((e) => {
  // Initialize waitlist form handling
  handleWaitlistSubmit();
  
  $.get(summaryLink, (data) => {
    $checkoutDropdown.html(data);
    handleContinueButton();
  });

  // add-to-cart: button can be on either of the following:
  // shop-home, product-detail, product-recommended, add-ons page and on checkout-summary
  $(
    ".home__products-overview, .product, .product__info__recommended_products"
  ).on("click", ".product-form button, .product-item button", (e) => {
    e.preventDefault();
    const $form = $(e.target.form);
    const $error = $form
      .closest(
        ".product-item,.product__info,.product__info__recommended_products"
      )
      .find(".product__info__form-error");
    const quantity = $form.find('input[name="quantity"]').val();
    const variant = $form.find('[name="variant"]').val();
    updateFragments();
    plausibleEvent("AddToCart",{ 
      props: { 
        variant: variant,
        quantity: quantity,
        source_url: location.href
      } 
    });
    $.ajax({
      url: $form.attr("action"),
      type: "POST",
      data: {
        variant,
        quantity,
      },
      success: ({ next }) => {
        $.get(next, (body) => {
          const fragment = document.createDocumentFragment();
          $(fragment).html(body);
          const checkout = fragment.querySelector(
            ".checkout-preview,.checkout__review"
          );
          $(".checkout-preview,.checkout__review").replaceWith(checkout);
        });
        e.target.textContent = gettext("Added");
        e.target.classList.add("btn-checked");
        setTimeout(() => {
          e.target.textContent = gettext("Add to cart");
          e.target.classList.remove("btn-checked");
          handleContinueButton();
        }, 2000);
        $error.attr("hidden", true);
        onAddToCheckoutSuccess();
      },
      error: (response) => {
        const errorText = getAjaxError(response);
        e.target.classList.remove("btn-checked");
        $error.html(errorText);
        $error.attr("hidden", false);
        track(errorText, "Error", "Shop");
        const e = new Error();
        plausibleEvent("Error",{ 
          props: { 
            source_url: location.href,
            message: errorText,
            stack: e.stack
          } 
        })
      },
    });
  });

  const $cartContainer = $(".home__checkout-preview, .cart");

  $cartContainer.on("click", ".checkout-preview__clear", (e) => {
    $.ajax({
      url: $(".checkout-preview__clear").data("action"),
      method: "POST",
      data: {},
      success: (response) => {
        $badge.html(response.numItems);
        location.reload();
      },
    });
  });

  // Checkout quantity form
  $cartContainer.on("click", ".checkout-preview-item-delete", (e) => {
    const $parent = $(e.target).closest(".checkout-preview__line");
    const $checkoutFormUrl = $parent.find(".form-checkout");
    $.ajax({
      url: $checkoutFormUrl.attr("action"),
      method: "POST",
      data: { quantity: 0 },
      success: (response) => {
        if (response.checkout.numLines >= 1) {
          $parent.fadeOut();
          $badge.html(response.checkout.numItems);
          $cartContainer
            .find(".checkout-preview-subtotal")
            .html(response.total);
        } else {
          location.reload();
        }
        deliveryAjax();
      },
    });
  });

  $cartContainer.on("change", ".checkout-preview__line", (e) => {
    const $target = $(e.target);
    const $form = $(e.target.form);
    const action = $form.attr("action");
    const quantity = $form.find('input[name="quantity"]').val();
    const $qunatityError = $target
      .closest(".checkout-preview__line")
      .find(".checkout-preview__line__quantity-error");
    $.ajax({
      url: action,
      method: "POST",
      data: { quantity },
      success: (response) => {
        if (quantity === 0) {
          if (response.checkout.numLines === 0) {
            location.reload();
          } else {
            $target.fadeOut();
          }
        } else {
          $target
            .closest(".checkout-preview__line")
            .find(".checkout-preview-item-price p")
            .html(
              response.subtotal
                .replace("€", '<span class="currency">€</span>')
                .replace("$", '<span class="currency">$</span>')
                .replace("£", '<span class="currency">£</span>')
                .replace("CHF", '<span class="currency">CHF</span>')
            );
        }
        $badge.html(response.checkout.numItems);
        $qunatityError.html("");
        deliveryAjax();
      },
      error: (response) => {
        const errorText = getAjaxError(response);
        $qunatityError.html(errorText);
        track(errorText, "Error", "Shop");
        const e = new Error();
        plausibleEvent("Error",{ 
          props: { 
            source_url: location.href,
            message: errorText,
            stack: e.stack
          } 
        })
      },
    });
  });

  // Delivery information
  const deliveryAjax = (e) => {
    const $deliveryForm = $(".deliveryform");
    const crsfToken = $deliveryForm.data("crsf");
    const $checkoutSubtotal = $(".checkout__subtotal");
    const newCountry = $('select[name="country"]').val();
    $.ajax({
      url: $html.data("shipping-options-url"),
      type: "POST",
      data: {
        csrfmiddlewaretoken: crsfToken,
        country: newCountry,
      },
      success: (data) => {
        $checkoutSubtotal.html(data);
        showResellerNote($checkoutSubtotal.get(0));
        updateFragments();
      },
    });
  };

  $cartContainer.on("change", 'select[name="country"]', deliveryAjax);
  const $accessories = $("#accessories");
  const $metal = $("#metal");

  function isCheckoutSection(str) {
    return str === "metal" || str === "accessories" || str === "merchandise";
  }

  const continueHandler = function (e) {
    if ($metal.length && location.hash === "#products") {
      $metal.get(0).scrollIntoView({ behavior: "smooth" });
      history.replaceState(null, null, "#metal");
      track("#metal", "Goto", "Shop");
      plausibleEvent("Goto",{ 
        props: {
          source_url: location.href,
          to: "#metal"
        } 
      })
      e.preventDefault();
      return;
    }
    if ($accessories.length && location.hash === "#metal") {
      $accessories.get(0).scrollIntoView({ behavior: "smooth" });
      history.replaceState(null, null, "#accessories");
      track("#accessories", "Goto", "Shop");
      plausibleEvent("Goto",{ 
        props: {
          source_url: location.href,
          to: "#accessories"
        } 
      })
      e.preventDefault();
      return;
    }
    track("/checkout/start", "Goto", "Shop");
    plausibleEvent("Goto",{ 
      props: { 
        source_url: location.href,
        to: "/checkout/start"
      } 
    })
    // else go to the url of the link that was clicked
  };

  // mobile checkout button
  const proceedBtn = document.querySelector(`.continue a.group-${window.testGroup}`);
  if (proceedBtn) {
    if (isCheckoutSection(location.hash.slice(1))) {
      proceedBtn.textContent = gettext("Checkout");
    }
    proceedBtn.addEventListener("click", continueHandler);
  }

  // desktop checkout button
  let proceedDesktopBtn = document.querySelector(
    `.home .checkout-preview__checkout a.group-${window.testGroup}`
  );
  if (proceedDesktopBtn) {
    if (isCheckoutSection(location.hash.slice(1))) {
      proceedBtn.textContent = gettext("Continue");
    }
  }
  $cartContainer.on("click", ".checkout-preview__checkout a", continueHandler);

  function handleContinueButton() {
    setTimeout(() => {
      const badge = $badge.text().trim();
      if (badge !== "0") {
        $html.addClass("js-show-contiune");
        proceedDesktopBtn = document.querySelector(
          ".home .checkout-preview__checkout a"
        );
      }
    }, 2000);
  }

  const products = document.querySelector("#products");
  const metal = document.querySelector("#metal");
  const accessories = document.querySelector("#accessories");
  const merchandise = document.querySelector("#merchandise");
  if (
    products &&
    metal && 
    accessories &&
    merchandise &&
    "IntersectionObserver" in window
  ) {
    const intersection = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          history.replaceState(
            null,
            null,
            entry.isIntersecting
              ? `#${id}`
              : location.href.replace(`#${id}`, "")
          );
          const buttonText =
            isCheckoutSection(id) && entry.isIntersecting
              ? gettext("Checkout")
              : gettext("Continue");
          if (proceedBtn) {
            proceedBtn.textContent = buttonText;
          }
          if (proceedDesktopBtn) {
            proceedDesktopBtn.textContent = buttonText;
          }
        });
      },
      { rootMargin: "-50% 0px -50% 0px" }
    );
    intersection.observe(products);
    intersection.observe(metal);
    intersection.observe(accessories);
    intersection.observe(merchandise);
  }

  const collapsedCheckoutReview = document.querySelector(
    ".js-collapse-checkout-review"
  );
  const checkoutReview = document.querySelector(".checkout__review");
  if (collapsedCheckoutReview && checkoutReview) {
    checkoutReview.addEventListener("click", () => {
      collapsedCheckoutReview.classList.remove("js-collapse-checkout-review");
    });
  }

  (function(){
    function toogleButtons(enabled) {
      continue_buttons.forEach(btn => {
        btn.disabled = !enabled;
      });
    }
    function disableButtons() {
      if (voucherField && voucherField.value) {
        toogleButtons(false);
      }
    }
    let timeoutId;
    function handleInputChange() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (voucherField.value) {
          voucherForm.submit();
        }
        toogleButtons(true);
      }, 2000);
    }
    const continue_buttons = document.querySelectorAll('[form="place-order-form"]');
    const voucherForm = document.getElementsByClassName("voucher-add-form")[0];
    const voucherField = document.getElementById("id_discount-voucher");
    if (voucherField) {
      voucherField.addEventListener("input", function() {
        handleInputChange();
        disableButtons();
      });
    }
  })();
});

// Waitlist form handling
export const handleWaitlistSubmit = () => {
  const waitlistForm = document.getElementById('waitlist-form');
  if (!waitlistForm) return;

  waitlistForm.addEventListener('submit', function(e) {
    e.preventDefault();
    const email = this.querySelector('input[name="email"]').value;
    const variant = this.querySelector('input[name="variant"]').value;
    const csrfToken = this.querySelector('input[name="csrfmiddlewaretoken"]').value;
    const waitlistUrl = waitlistForm.dataset.url;
    
    $.ajax({
      url: waitlistUrl,
      method: 'POST',
      data: {
        email: email,
        variant: variant,
        csrfmiddlewaretoken: csrfToken
      },
      success: function(response) {
        document.getElementById('waitlist-initial').hidden = true;
        document.getElementById('waitlist-success').hidden = false;
      },
      error: function(response) {
        let errorText = '';
        try {
          errorText = $.parseJSON(response.responseText).error.email;
        } catch (e) {
          errorText = 'An error occurred. Please try again.';
        }
        const errorElement = document.getElementById('waitlist-error');
        errorElement.textContent = errorText;
        errorElement.hidden = false;
      }
    });
  });
};
