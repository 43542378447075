import isEqual from "lodash/isEqual";
import $ from "jquery";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { Component } from "react";
import PropTypes from "prop-types";

import AttributeSelectionWidget from "./AttributeSelectionWidget";
import QuantityInput from "./QuantityInput";
import * as queryString from "query-string";
import { showCarousel } from "./VariantImageCarousel";

export default observer(
  class VariantPicker extends Component {
    static propTypes = {
      onAddToCheckoutError: PropTypes.func.isRequired,
      onAddToCheckoutSuccess: PropTypes.func.isRequired,
      store: PropTypes.object.isRequired,
      variantAttributes: PropTypes.array.isRequired,
      variants: PropTypes.array.isRequired
    };

    constructor(props) {
      super(props);
      const { variants } = this.props;

      const variant = variants.filter(
        v => !!Object.keys(v.attributes).length
      )[0];
      const params = queryString.parse(location.search);
      let selection = {};
      if (Object.keys(params).length) {
        Object.keys(params).some(name => {
          const valueName = params[name];
          const attribute = this.matchAttributeBySlug(name);
          const value = this.matchAttributeValueByName(attribute, valueName);
          if (attribute && value) {
            selection[attribute.pk] = value.pk.toString();
          }
        });
      }
      this.state = {
        checked: false,
        errors: {},
        quantity: 1,
        selection: selection,
        email: '',
        waitlistSuccess: false
      };
      this.matchVariantFromSelection();
    }

    checkVariantAvailability = () => {
      const { store } = this.props;
      return store.variant.availability;
    };

    handleAddToCheckout = () => {
      const {
        onAddToCheckoutSuccess,
        onAddToCheckoutError,
        store
      } = this.props;
      const { quantity } = this.state;
      if (quantity > 0 && !store.isEmpty) {
        const url = $('#variant-picker').data('action-checkout');
        plausibleEvent("AddToCart",{
          props: {
            variant:  store.variant.id,
            quantity: quantity,
            source_url: location.href
          }
        });
        $.ajax({
          url: url,
          method: "post",
          data: {
            quantity: quantity,
            variant: store.variant.id
          },
          success: () => {
            this.setState({ checked: true });
            setTimeout(() => {
              this.setState({ checked: false });
            }, 2000);
            onAddToCheckoutSuccess();
          },
          error: response => {
            onAddToCheckoutError(response);
          }
        });
      }
    };

    handleAttributeChange = (attrId, valueId) => {
      this.setState(
        {
          selection: Object.assign({}, this.state.selection, {
            [attrId]: valueId
          })
        },
        () => {
          this.matchVariantFromSelection();
          let params = {};
          Object.keys(this.state.selection).forEach(attrId => {
            const attribute = this.matchAttribute(attrId);
            const value = this.matchAttributeValue(
              attribute,
              this.state.selection[attrId]
            );
            if (attribute && value) {
              params[attribute.slug] = value.slug;
            }
          });
          history.replaceState(null, null, "?" + queryString.stringify(params));
          showCarousel(`carousel-${attrId}_${valueId}`);
        }
      );
    };

    handleQuantityChange = event => {
      this.setState({ quantity: parseInt(event.target.value) });
    };

    handleEmailChange = event => {
      this.setState({ email: event.target.value });
    };

    onAddToWaitlistSuccess = () => {
      this.setState({
        waitlistSuccess: true
      });
      const $error = $(".product__waitlist__form-error");
      $error.attr("hidden", true);
    };

    handleWaitlistSubmit = (event) => {
      event.preventDefault();
      const { email } = this.state;
      const { store } = this.props;
      const url = $('#variant-picker').data('action-waitlist');
      if (email) {

        $.ajax({
          url: url,
          method: "POST",
          data: {
            email,
            variant: store.variant.id
          },
          success: (response) => {
            this.onAddToWaitlistSuccess();
          },
          error: (response) => {
            const errorText = $.parseJSON(response.responseText).error.email;
            const $error = $(".product__waitlist__form-error");
            $error.html(errorText);
            $error.attr("hidden", false);
          },
        });
      }
    };

    matchAttribute = id => {
      const { variantAttributes } = this.props;
      const match = variantAttributes.filter(
        attribute => attribute.pk.toString() === id
      );
      return match.length > 0 ? match[0] : null;
    };

    matchAttributeBySlug = slug => {
      const { variantAttributes } = this.props;
      const match = variantAttributes.filter(
        attribute => attribute.slug === slug
      );
      return match.length > 0 ? match[0] : null;
    };

    matchAttributeValue = (attribute, id) => {
      const match = attribute.values.filter(
        attribute => attribute.pk.toString() === id
      );
      return match.length > 0 ? match[0] : null;
    };

    matchAttributeValueByName = (attribute, name) => {
      const match = attribute
        ? attribute.values.filter(value => value.slug === name)
        : [];
      return match.length > 0 ? match[0] : null;
    };

    matchVariantFromSelection() {
      const { store, variants } = this.props;
      let matchedVariant = null;
      variants.forEach(variant => {
        if (isEqual(this.state.selection, variant.attributes)) {
          matchedVariant = variant;
        }
      });
      store.setVariant(matchedVariant);
    }

    render() {
      const { store, variantAttributes } = this.props;
      const { checked, errors, selection, quantity, email, waitlistSuccess } = this.state;
      const disableAddToCheckout =
        store.isEmpty || !this.checkVariantAvailability();

      const variantSelected = !store.isEmpty;
      const inPreorder = store.variant.preorder;
      const outOfStock = variantSelected && !store.variant.availability;
      const addToCheckoutBtnClasses = classNames({
        "btn btn-primary": true,
        "btn-checked": checked,
        disabled: disableAddToCheckout,
      });

      return (
        <div>
          {variantAttributes.map((attribute, i) => (
            <AttributeSelectionWidget
              attribute={attribute}
              handleChange={this.handleAttributeChange}
              key={i}
              selected={selection[attribute.pk]}
            />
          ))}
          <div className="clearfix">
            {/* Always show quantity input and button when no variant selected or when variant is in stock */}
            {(!variantSelected || !outOfStock) && (
              <>
                <QuantityInput
                  errors={errors.quantity}
                  handleChange={this.handleQuantityChange}
                  quantity={quantity}
                />
                <div className="form-group product__info__button">
                  <button
                    className={addToCheckoutBtnClasses}
                    onClick={this.handleAddToCheckout}
                    disabled={disableAddToCheckout}
                  >
                    {disableAddToCheckout
                    ? pgettext("Product details primary action", "Select a variant first")
                    : (checked
                  ? pgettext("Product details primary action", "Added")
                  : pgettext("Product details primary action", "Add to cart")
                )}
                  </button>
                </div>
              </>
            )}
          </div>
          {/* Only show out of stock message and waitlist form when a variant is selected and it's out of stock */}
          {(variantSelected && outOfStock) && (
              <>
              <p className="alert alert-warning">{pgettext("Out of stock warning", "This item is out of stock.")}</p>
              <div>
              {waitlistSuccess ? (
                <p className="alert alert-success">{pgettext("Storefront waitlist signup message", "We will notify you via email once the selected item is back in stock.")}</p>
              ) : (
                <>
                <p><strong>{pgettext("Notify me when item is back in stock", "Notify me when the item is back in stock")}</strong></p>
                <form method="post" className="form-group" onSubmit={this.handleWaitlistSubmit}>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={this.handleEmailChange}
                      placeholder={pgettext("Enter your email", "Enter your email")}
                      required
                      className="form-control"
                    />
                    <div className="input-group-append">
                      <button type="submit" className="btn btn-primary" style={{ padding: '0 10px' }}>
                        {pgettext("Submit", "Submit")}
                      </button>
                    </div>
                  </div>
                </form>
                </>
              )}
              </div>
              </>
          )}
          {(inPreorder && !outOfStock)
              ? (
              <p className="alert alert-warning">{pgettext("Item in high demand warning", "Item in high demand. Orders made today will be shipped by ")}{ store.variant.estimated_shipping_date }{pgettext("Item in high demand warning", ".")}</p>
            ): ""}
        </div>
      );
    }
  }
);
